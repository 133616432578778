import React, { useCallback, useContext, useEffect, useState } from "react";
import FireSwitch from "./components/FireSwitch/FireSwitch";
import FireInput from "./components/Input/FireInput";
import FireButton from "./components/FireButton/FireButton";

import { FormGroup } from "@mui/material";
import { capitalizeFirstLetterOnly } from "assets/helpers";
import { cloneDeep } from "lodash";
import { GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { AppContext } from "components/App/App";

import styles from "./AddForm.module.css";

const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });

const initialState = {
  "document-name": { value: "", component: "input", shouldCreate: false },
  "background-color": { value: "", component: "input" },
  "background-image": { value: "", component: "input" },
  description: { value: "", component: "input" },
  github: { value: "", component: "input" },
  icon: { value: "", component: "input" },
  site: { value: "", component: "input" },
  views: { value: 0, component: "input", type: "number" },
  "under-construction": { value: "off", component: "switch" },
};

function AddForm(props) {
  const { className } = props;
  const { firestore } = useContext(AppContext);
  const { auth, db, doc, setDoc } = firestore;
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [inputs, setInputs] = useState(initialState);

  const setInput = (event) => {
    const { id, value } = event.target;
    const cloneInputs = cloneDeep(inputs);
    const findInput = cloneInputs[id];

    findInput.value = value;
    setInputs(cloneInputs);
  };

  const fireSignIn = useCallback(() => {
    if (auth) {
      signInWithPopup(auth, provider)
        .then(() => {
          setIsSignedIn(true);
        })
        .catch((error) => {
          console.error(error.message);
          setIsSignedIn(false);
        });
    }
  }, [auth]);

  const fireSignOut = useCallback(() => {
    signOut(auth).then(() => setIsSignedIn(false));
  }, [auth]);

  const submitForm = useCallback(() => {
    const { value: documentName } = inputs["document-name"];
    const fireDoc = {};

    Object.keys(inputs).forEach((key) => {
      const { value, component, shouldCreate = true } = inputs[key];

      if (shouldCreate) {
        let formatKey = key.split("-");
        let formatValue;

        if (formatKey.length > 1) {
          formatKey = formatKey[0] + capitalizeFirstLetterOnly(formatKey[1]);
        } else {
          formatKey = formatKey[0];
        }

        if (component === "switch") {
          formatValue = value === "on";
        } else {
          formatValue = value;
        }

        fireDoc[formatKey] = formatValue;
      }
    });

    setDoc(doc(db, "projects", documentName), fireDoc);
    setInputs(initialState);
  }, [doc, inputs, db, setDoc]);

  useEffect(() => {
    auth?.onAuthStateChanged(function (user) {
      setIsSignedIn(user);
    });
  }, [auth]);

  return (
    <FormGroup className={`${styles.wrapper} ${className || ""}`}>
      {Object.keys(inputs).map((key) => {
        const obj = inputs[key];
        obj.id = key;
        const { component } = obj;
        const children = key
          .split("-")
          .map((child) => capitalizeFirstLetterOnly(child))
          .join(" ");

        return component === "input" ? (
          <FireInput key={key} onChange={setInput} {...obj}>
            {children}
          </FireInput>
        ) : (
          <FireSwitch key={key} onChange={setInput} {...obj}>
            {children}
          </FireSwitch>
        );
      })}
      {!isSignedIn && (
        <FireButton icon={faGoogle} onClick={fireSignIn}>
          Sign In
        </FireButton>
      )}
      {isSignedIn && (
        <FireButton icon={faGoogle} onClick={fireSignOut}>
          Sign Out
        </FireButton>
      )}
      <FireButton onClick={submitForm} disabled={!isSignedIn}>
        Submit
      </FireButton>
    </FormGroup>
  );
}

export default AddForm;
