import React from "react";
import profileSrc from "assets/profile-landscape.jpg";

import styles from "./ProfileImage.module.css";

function Profile() {
  return (
    <div className={styles.wrapper}>
      <img alt="Rameal Profile" src={profileSrc} className={styles.image} />
    </div>
  );
}

export default Profile;
