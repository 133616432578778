import React from "react";
import { FormControl, Input, InputLabel } from "@mui/material";

function FireInput(props) {
  const { children, id, value, type, onChange } = props;

  return (
    <FormControl>
      <InputLabel htmlFor={id}>{children}</InputLabel>
      <Input id={id} value={value} type={type} onChange={onChange} />
    </FormControl>
  );
}

export default FireInput;
