import React from "react";
import { Button, Container } from "@mui/material";
import { PAGES } from "assets/constants";

import styles from "./Menu.module.css";

function Menu() {
  //const matches = useMediaQuery("(max-width:767px)");

  return (
    <Container className={styles.wrapper}>
      <div className={styles.top_wrapper}>
        {PAGES.map((page) => (
          <Button key={page}>{page}</Button>
        ))}
      </div>
    </Container>
  );
}

export default Menu;
