import React from "react";
import { Button, Card } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPaperPlane } from "@fortawesome/free-solid-svg-icons";

import styles from "./ProfileCtas.module.css";

function ProfileCtas() {
  return (
    <Card
      className={`${styles.wrapper} animate__animated animate__slideInRight`}
      raised
    >
      <h2>Hi there! I'm</h2>
      <h1>Rameal Nabeeh</h1>
      <h3>Software Developer</h3>
      <div className={styles.ctas_wrapper}>
        <Button
          endIcon={<FontAwesomeIcon icon={faDownload} />}
          href="https://1drv.ms/w/s!AguOUPwYSU1mj549mu5x1URwxS6Zxw?e=a6VWR6"
          rel="noreferrer noopener"
          target="_blank"
          variant="contained"
        >
          Resume
        </Button>
        <Button
          href="mailto: rameal.nabeeh@gmail.com"
          variant="contained"
          endIcon={<FontAwesomeIcon icon={faPaperPlane} />}
        >
          Contact Me
        </Button>
      </div>
    </Card>
  );
}

export default ProfileCtas;
