import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import React from "react";
import styles from "./FireButton.module.css";

function FireButton(props) {
  const { children, icon, onClick, disabled = false } = props;
  return (
    <Button
      className={styles.button}
      variant="contained"
      startIcon={icon && <FontAwesomeIcon icon={icon} />}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}

export default FireButton;
