import React from "react";
import ProfileCtas from "./components/ProfileCtas";
import ProfileImage from "./components/ProfileImage";

import styles from "./ProfileBanner.module.css";

function ProfileBanner() {
  return (
    <div className={styles.wrapper}>
      <ProfileCtas />
      <ProfileImage />
    </div>
  );
}

export default ProfileBanner;
