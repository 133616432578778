import React, { useState, useMemo, useCallback, useContext } from "react";
import { Card, Button, useMediaQuery } from "@mui/material";
import { capitalizeFirstLetterOnly } from "assets/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import {
  faGlobe,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
import debounce from "lodash.debounce";
import underConstructionBackground from "assets/under-construction.jpeg";
import { AppContext } from "components/App/App";

import styles from "./Project.module.css";

function Project(props) {
  const {
    backgroundColor,
    backgroundImage,
    description,
    github,
    icon,
    id,
    site,
    underConstruction,
    views,
  } = props;
  const { firestore } = useContext(AppContext);
  const { db, doc, updateDoc } = firestore;
  const [hiddenWrapperCSS, setHiddenWrapperCSS] = useState("display-none");
  const [hiddenIconMiniCSS, setHiddenIconMiniCSS] = useState("display-none");
  const isMobile = useMediaQuery("(max-width:767px)");
  const iconMiniClass = `${styles.icon_mini} animate__animated ${hiddenIconMiniCSS}`;

  const appName = id
    .split("-")
    .map((word) => capitalizeFirstLetterOnly(word))
    .join(" ");

  const handleHover = (enter) => {
    setHiddenWrapperCSS(`animate__slide${enter ? "InUp" : "OutDown"}`);
    setHiddenIconMiniCSS(`animate__fade${enter ? "In" : "Out"}`);
  };
  const debouncedHandleHover = useMemo(
    () => debounce((enter) => handleHover(enter), 1000, { leading: true }),
    []
  );

  const handleOnClick = useCallback(
    (href) => {
      window.open(href, "_blank", "noreferrer noopener");

      const projectRef = doc(db, "projects", id);
      updateDoc(projectRef, {
        views: views + 1,
      });
    },
    [db, doc, id, updateDoc, views]
  );

  return (
    <Card
      raised
      className={`${styles.wrapper} animate__animated animate__fadeIn`}
      onMouseEnter={() => debouncedHandleHover(true)}
      onMouseLeave={() => debouncedHandleHover(false)}
    >
      <div className={styles.images_wrapper}>
        <img
          alt="Background"
          src={
            underConstruction ? underConstructionBackground : backgroundImage
          }
          className={styles.background}
        />

        <i
          style={{
            backgroundColor: underConstruction
              ? "var(--amber)"
              : backgroundColor,
          }}
        />
        {underConstruction ? (
          <FontAwesomeIcon icon={faScrewdriverWrench} className={styles.icon} />
        ) : (
          <img alt="Icon" src={icon} className={styles.icon} />
        )}
      </div>
      <div className={styles.text_wrapper}>
        <h2>{appName}</h2>
        <div
          className={`${styles.hidden_text_wrapper} animate__animated ${hiddenWrapperCSS}`}
        >
          {underConstruction ? (
            <FontAwesomeIcon
              icon={faScrewdriverWrench}
              className={iconMiniClass}
            />
          ) : (
            <img alt="Mini Icon" src={icon} className={iconMiniClass} />
          )}
          <h3>
            {description}
            {underConstruction ? (
              <>
                <br />
                <span style={{ color: "var(--amber)" }}>
                  (Under Construction)
                </span>
              </>
            ) : (
              ""
            )}
          </h3>
          <div>
            {site && (
              <Button
                variant="contained"
                onClick={() => handleOnClick(site)}
                startIcon={<FontAwesomeIcon icon={faGlobe} />}
                title="Website"
              >
                {isMobile ? "" : "Website"}
              </Button>
            )}
            {github && (
              <Button
                variant="outlined"
                onClick={() => handleOnClick(github)}
                startIcon={<FontAwesomeIcon icon={faGithub} />}
                title="Repo"
              >
                {isMobile ? "" : "Repo"}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}

export default Project;
