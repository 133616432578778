import React, { useContext, useEffect, useState } from "react";
import Divider from "library/Divider";
import Project from "./components/Project";
import AddForm from "components/AddForm";
import { AppContext } from "components/App/App";

import styles from "./Projects.module.css";

function Projects() {
  const { flags, firestore } = useContext(AppContext);
  const { collection, db, onSnapshot, orderBy, query } = firestore;
  const { FF_ADD_FORM = false } = flags;
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    if (db) {
      const projectsRef = collection(db, "projects");
      const projectsByView = query(
        projectsRef,
        orderBy("underConstruction", "asc")
      );

      const unsubscribe = onSnapshot(projectsByView, (querySnapshot) => {
        const dbProjects = [];
        querySnapshot.forEach((project) =>
          dbProjects.push({
            ...project.data(),
            id: project.id,
            backgroundImage:
              project?.data()?.backgroundImage ||
              "https://images.template.net/87288/free-technology-powerpoint-background-txgfo.jpg",
          })
        );

        setProjects(dbProjects);
      });

      return unsubscribe;
    }
  }, [collection, db, onSnapshot, orderBy, query]);

  return (
    <div className={styles.wrapper}>
      <Divider>Projects</Divider>
      <div className={styles.projects_wrapper}>
        {projects.map((project) => (
          <Project key={project.id} {...project} />
        ))}
      </div>
      {FF_ADD_FORM && <AddForm className={styles.add_form} />}
    </div>
  );
}

export default Projects;
