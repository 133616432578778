import React from "react";
import styles from "./Divider.module.css";

function Divider(props) {
  const { children } = props;
  return (
    <div className={styles.wrapper}>
      <span>{children}</span>
    </div>
  );
}

export default Divider;
