import React, { useEffect, useState } from "react";
import Menu from "components/Menu";
import Projects from "components/Projects/Projects";
import ProfileBanner from "../ProfileBanner";

import { Box, Container } from "@mui/material";
import { initializeApp } from "firebase/app";
import {
  collection,
  doc,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";

import "animate.css";
import styles from "./App.module.css";

const firebaseConfig = {
  apiKey: "AIzaSyADcW5J5H7bbSvw4e4-KNDXPAHFjZIBKvk",
  authDomain: "personal-website-b435a.firebaseapp.com",
  projectId: "personal-website-b435a",
  storageBucket: "personal-website-b435a.appspot.com",
  messagingSenderId: "902402283247",
  appId: "1:902402283247:web:0670ac56eda10fc969ad1a",
  measurementId: "G-RJ9Y57G87B",
};

//TODO: Setup analytics
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export const AppContext = React.createContext();

function App() {
  const [flags, setFlags] = useState({});
  const { FF_MENU = false } = flags;

  useEffect(() => {
    const testingRef = doc(db, "flags", "data");

    const unsubscribe = onSnapshot(testingRef, (doc) => {
      const flags = doc?.data() || {};

      setFlags(flags);
    });

    return unsubscribe;
  }, []);

  return (
    <AppContext.Provider
      value={{
        firestore: {
          auth,
          collection,
          db,
          doc,
          onSnapshot,
          orderBy,
          query,
          setDoc,
          updateDoc,
        },
        flags: flags,
      }}
    >
      <Box>
        <Container className={styles.container}>
          {FF_MENU && <Menu />}
          <ProfileBanner />
          <Projects />
        </Container>
      </Box>
    </AppContext.Provider>
  );
}

export default App;
