import { FormControlLabel, Switch } from "@mui/material";
import React from "react";

function FireSwitch(props) {
  const { children, onChange, id } = props;

  return (
    <FormControlLabel
      control={<Switch onChange={onChange} id={id} />}
      label={children}
      labelPlacement="start"
    />
  );
}

export default FireSwitch;
